import React from "react";

/** ============================================================================
 * @page
 * 404
 */
const PageNotFound = () => (
  <div>
    <p>Page not found</p>
  </div>
);

export default PageNotFound;
